import React from 'react';
import './ExploreContainer.css';
import { gql, useQuery } from '@apollo/client';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
} from '@ionic/react';

const GET_JOBS = gql`
  query GetJobs {
    remotes {
      jobs(orderBy: createdAt_DESC) {
        id
        title
        slug
        description
        applyUrl
        company {
          name
          slug
        }
      }
    }
  }
`;
interface ContainerProps {}

interface JobInterface {
  id: string;
  title: string;
  slug: string;

  description: string;
  applyUrl: string;

  isPublished: boolean;
  isFeatured: boolean;
  locationNames: string;
  userEmail: string;
  company: {
    name: string;
    slug: string;
  };
}
interface JobsInterface {
  remotes: {
    jobs: JobInterface[];
  }[];
}

const JobList: React.FC = () => {
  const { loading, error, data } = useQuery<JobsInterface>(GET_JOBS);
  if (loading) return <>{'loading...'}</>;
  if (error) return <>{`Error: ${error.message}`}</>;

  return (
    <IonGrid>
      <IonRow>
        {data?.remotes[0].jobs?.map((job) => (
          <IonCol key={job.id}>
            <IonCard href={`/job/${job.company.slug}/${job.slug}`}>
              <IonCardHeader>
                <IonCardTitle>{job.title}</IonCardTitle>
                <IonCardSubtitle>{job.company.name}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  {job.applyUrl ? (
                    <IonButton
                      slot="end"
                      target="_blank"
                      rel="external"
                      href={job.applyUrl}
                    >
                      Apply
                    </IonButton>
                  ) : null}
                </IonItem>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>

      {/*<IonList>
      {data?.remotes[0].jobs?.map((job) => (
        <IonItem key={job.id}>
          {job.title}
          {job.applyUrl ? (
            <IonButton
              slot="end"
              target="_blank"
              rel="external"
              href={job.applyUrl}
            >
              Apply
            </IonButton>
          ) : null}
        </IonItem>
      ))}
    </IonList>*/}
    </IonGrid>
  );
};

const ExploreContainer: React.FC<ContainerProps> = () => {
  return <JobList />;
};

export default ExploreContainer;
