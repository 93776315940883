import { ApolloClient, InMemoryCache } from '@apollo/client';

const Endpoint = 'https://api.graphql.jobs';

const Client = new ApolloClient({
  uri: Endpoint,
  cache: new InMemoryCache(),
});

export default Client;
