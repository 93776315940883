import React from 'react';
import './job.css';
import { gql, useQuery } from '@apollo/client';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';

const GET_JOB = gql`
  query GetJob($companySlug: String!, $slug: String!) {
    job(input: { companySlug: $companySlug, jobSlug: $slug }) {
      id
      title
      description
      applyUrl
      company {
        name
      }
    }
  }
`;

interface JobProps
  extends RouteComponentProps<{
    companySlug: string;
    slug: string;
  }> {}

interface JobInterface {
  job: {
    id: string;
    title: string;
    slug: string;

    description: string;
    applyUrl: string;

    isPublished: boolean;
    isFeatured: boolean;
    locationNames: string;
    userEmail: string;
    company: {
      name: string;
    };
  };
}

const Job: React.FC<JobProps> = (props: JobProps) => {
  const { companySlug, slug } = props.match.params;
  console.log('checing props: ', companySlug, slug);
  const { loading, error, data } = useQuery<
    JobInterface,
    { companySlug: string; slug: string }
  >(GET_JOB, {
    variables: { companySlug, slug },
  });
  if (loading) return <>{'loading...'}</>;
  if (error) return <>{`Error: ${error.message}`}</>;

  return (
    <IonGrid>
      <IonRow>
        {data?.job ? (
          <IonCol key={data?.job.id}>
            <IonCard href={`/job/${data?.job.id}`}>
              <IonCardHeader>
                <IonCardTitle>{data?.job.title}</IonCardTitle>
                <IonCardSubtitle>{data?.job.company.name}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  {data?.job.applyUrl ? (
                    <IonButton
                      slot="end"
                      target="_blank"
                      rel="external"
                      href={data?.job.applyUrl}
                    >
                      Apply
                    </IonButton>
                  ) : null}
                </IonItem>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ) : null}
      </IonRow>

      {/*<IonList>
      {data?.remotes[0].jobs?.map((job) => (
        <IonItem key={job.id}>
          {job.title}
          {job.applyUrl ? (
            <IonButton
              slot="end"
              target="_blank"
              rel="external"
              href={job.applyUrl}
            >
              Apply
            </IonButton>
          ) : null}
        </IonItem>
      ))}
    </IonList>*/}
    </IonGrid>
  );
};

export default Job;
